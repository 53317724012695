import React, { useState, useEffect } from "react";
import Vendors from "./Vendors/Vendors";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const renewalsMutation = gql`
  mutation softwareRenewals(
    $id: ID
    $softwareRenewals: [UpdateSoftwareRenewal]
  ) {
    updateSoftwareRenewals(
      input: { id: $id, softwareRenewals: $softwareRenewals }
    ) {
      id
      originalOrderId
      softwareId
      softwareVendor
      softwareProduct
      qty
      renewalDate
      contractLength
      renewalYear
      comment
    }
  }
`;

const classes = (theme) => ({
  root: {
    width: "100%",
  },
  card: {
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 10,
    },
    cursor: "pointer",
    maxWidth: "calc(100vw - 20px)",
  },
  sectionTitle: {
    color: "#6f6f6f",
    marginRight: 20,
    height: 40,
  },
  edit: {
    marginTop: -5,
  },
  flexGrow: {
    flexGrow: 1,
  },
  toolButtons: {
    marginBottom: 10,
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  errorIcon: {
    color: "#e54900",
  },
  cardContentRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
});

export default function SoftwareEstate(props) {
  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [client, setClient] = useState(props.client);
  const [loadedClient, setLoadedClient] = useState(props.client);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeVendor, setActiveVendor] = useState("Tableau");
  const [addingNew, setAddingNew] = useState(false);
  const [renewals, setRenewals] = useState([
    {
      name: "Tableau",
      count: 0,
      renewals: [],
    },
    {
      name: "Alteryx",
      count: 0,
      renewals: [],
    },
    {
      name: "Theobald",
      count: 0,
      renewals: [],
    },
    {
      name: "AWS",
      count: 0,
      renewals: [],
    },
  ]);

  useEffect(() => {
    setClient({ ...props.client });
    setLoadedClient({ ...props.client });
  }, [props.client]);

  function handleChangeActiveVendor(vendor) {
    setActiveVendor(vendor);
  }

  function compare(a, b) {
    if (a.count < b.count) {
      return 1;
    }
    if (a.count > b.count) {
      return -1;
    }
    return 0;
  }

  useEffect(
    () => {
      if (client.softwareRenewals) {
        const tableau = [];
        const alteryx = [];
        const aws = [];
        const theobald = [];
        client.softwareRenewals.forEach((renewal, idx) => {
          if (renewal.softwareVendor === "Tableau") {
            tableau.push(renewal);
          } else if (renewal.softwareVendor === "Alteryx") {
            alteryx.push(renewal);
          } else if (renewal.softwareVendor === "AWS") {
            aws.push(renewal);
          } else if (renewal.softwareVendor === "Theobald") {
            theobald.push(renewal);
          }
        });
        const tableauIdx = renewals.findIndex((vendor) => {
          return vendor.name === "Tableau";
        });
        const alteryxIdx = renewals.findIndex((vendor) => {
          return vendor.name === "Alteryx";
        });
        const awsIdx = renewals.findIndex((vendor) => {
          return vendor.name === "AWS";
        });
        const theobaldIdx = renewals.findIndex((vendor) => {
          return vendor.name === "Theobald";
        });
        const newRenewals = [...renewals];
        console.log("[SoftwareRenewals.js] newRenewals", newRenewals);
        console.log("[SoftwareRenewals.js] tableauIdx", tableauIdx);
        newRenewals[tableauIdx].count = tableau.length;
        newRenewals[tableauIdx].renewals = tableau;
        newRenewals[alteryxIdx].count = alteryx.length;
        newRenewals[alteryxIdx].renewals = alteryx;
        newRenewals[awsIdx].count = aws.length;
        newRenewals[awsIdx].renewals = aws;
        newRenewals[theobaldIdx].count = theobald.length;
        newRenewals[theobaldIdx].renewals = theobald;
        console.log("[SoftwareRenewals.js] newRenewals", newRenewals);
        if (!isEditing) {
          newRenewals.sort(compare);
        }
        setRenewals(newRenewals);
      }
    } /* eslint-disable */,
    [client.softwareRenewals] /* eslint-enable */
  );

  function addRenewal() {
    setAddingNew(true);
    const updatedRenewals = [...client.softwareRenewals];
    updatedRenewals.unshift({
      comment: "",
      contractLength: 0,
      id: client.softwareRenewals.length + 1,
      originalOrderId: "",
      qty: 0,
      renewalDate: "",
      renewalYear: 0,
      softwareId: "",
      softwareVendor: activeVendor,
    });
    setClient({ ...client, softwareRenewals: updatedRenewals });
  }

  function handleUpdateRenewal(renewal) {
    const idx = client.softwareRenewals.findIndex((r) => {
      return r.id === renewal.id;
    });
    const updatedRenewals = [...client.softwareRenewals];
    updatedRenewals[idx] = renewal;
    setClient({ ...client, softwareRenewals: updatedRenewals });
  }

  function handleCancel() {
    console.log("[SoftwareRenewals.js] loadedClient", loadedClient);
    setIsEditing(false);
    setError(null);
    setClient({ ...loadedClient });
  }

  function handleDeleteRenewal(id) {
    console.log("[SoftwareRenewals.js] Delete renewal", id);
    const idx = client.softwareRenewals.findIndex((r) => {
      return r.id === id;
    });
    const updatedRenewals = [...client.softwareRenewals];
    updatedRenewals.splice(idx, 1);
    setClient({ ...client, softwareRenewals: updatedRenewals });
  }

  const [
    saveRenewals,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(renewalsMutation, {
    update: (store, { data: { updateSoftwareRenewals }, error }) => {
      console.log(
        "[SoftwareRenewals.js] graphql update",
        updateSoftwareRenewals
      );
      // const newClient = {...client};
      // client.softwareRenewals = updateSoftwareRenewals;
      // setClient(newClient);
    },
    onError: (error) => {
      console.error("[SoftwareRenewals.js] GraphQL Error", error);
      setError(error.message);
    },
  });

  useEffect(() => {
    if (loading && !mutationLoading) {
      if (!mutationError) {
        console.log("[SoftwareRenewals.js] Mutation Complete");
        setError(null);
        setIsEditing(false);
        setLoading(false);
      } else {
        console.log("[SoftwareRenewals.js] Mutation Error");
        setIsEditing(true);
        setLoading(false);
      }
    } else if (!loading && mutationLoading) {
      console.log("[SoftwareRenewals.js] Saving Mutation");
      setError(null);
      setIsEditing(true);
      setLoading(true);
    } /* eslint-disable */
  }, [mutationLoading, mutationError]); /* eslint-enable */

  const onHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(250, 250, 250)",
  };

  const offHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(255, 255, 255)",
  };

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? onHoverStyle : offHoverStyle}
      onClick={() => (!isEditing ? setIsEditing(true) : null)}
      raised={isEditing}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        action={
          <EditIcon
            className={classes.editIcon}
            style={{ display: hover && !isEditing ? "inherit" : "none" }}
          />
        }
        title={
          <div className={classes.toolButtons}>
            Software Renewals
            <Button
              color="primary"
              size="small"
              className={classes.button}
              style={{ display: !isEditing ? "none" : "unset", marginLeft: 10 }}
              onClick={addRenewal}
            >
              Add New
            </Button>
          </div>
        }
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Vendors
          renewals={renewals}
          sector={client.sector}
          country={client.country}
          externalRef={client.externalRef}
          isEditing={isEditing}
          changeVendor={(vendor) => handleChangeActiveVendor(vendor)}
          editRenewal={(renewal) => handleUpdateRenewal(renewal)}
          addingNew={addingNew}
          deleteRenewal={(id) => handleDeleteRenewal(id)}
          auth0Profile={props.auth0Profile}
          refreshAuth0={props.refreshAuth0}
        />
      </CardContent>
      <Collapse in={isEditing}>
        <LinearProgress style={{ display: loading ? "inherit" : "none" }} />
        <CardActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              const submitRenewals = [];
              client.softwareRenewals.forEach((renewal) => {
                const updateRenewal = {...renewal};
                if (updateRenewal.originalOrderId === "") {
                  updateRenewal.originalOrderId = null;
                }
                if (updateRenewal["__typename"]) {
                  delete updateRenewal["__typename"];
                }
                if (updateRenewal["eduPriceList"]) {
                  delete updateRenewal["eduPriceList"];
                }
                if (updateRenewal["stdPriceList"]) {
                  delete updateRenewal["stdPriceList"];
                }
                submitRenewals.push(updateRenewal);
              });
              saveRenewals({
                variables: {
                  id: parseInt(client.id),
                  softwareRenewals: submitRenewals,
                },
              });
            }}
          >
            Save
          </Button>
          <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
        </CardActions>
      </Collapse>
    </Card>
  );
}
