import React, {useState, useEffect} from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Grid from "@mui/material/Grid";
import GeneralMessage from "../../GeneralMessage/GeneralMessage";
import CurrentSignature from "./CurrentSignature/CurrentSignature";
import NewSignature from "./NewSignature/NewSignature";
import Settings from "./Settings/Settings";
// eslint-disable-next-line import/no-webpack-loader-syntax
const standardTemplate = require('raw-loader!./Templates/Standard.html');

const classes = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

function Content() {
  const { user, logout } = useAuth0();

  const [ currentSignature, setCurrentSignature ] = useState('');
  const [ newSignature, setNewSignature ] = useState('');
  const [ oldSettings, setOldSettings ] = useState({});
  const [ settings, setSettings ] = useState({});

  useEffect(() => {
    if (user) {
      setSettings(user["https://sales.theinformationlab.co.uk/profile"]);
      setOldSettings(user["https://sales.theinformationlab.co.uk/profile"]);
    }
  }, [user]);

  const handleUpdatePreview = () => {
    var standardHtml = standardTemplate.default;
    const domain = user.email.split('@')[1];
    standardHtml = standardHtml.replace('[name]', settings.name || user.name);
    if (user.picture && settings.signaturePic) {
      standardHtml = standardHtml.replace('[profilePic]', `<td><img src="${user.picture}" style="width:96px;height:96px;border-radius:48px;margin-right:20px"></td>`);
    } else {
      standardHtml = standardHtml.replace('[profilePic]', '');
    }
    standardHtml = standardHtml.replace('[title]', settings.title && settings.title.length > 0 ? `${settings.title}, ` : '' || '');
    if (settings.mobile && settings.mobile.length > 0) {
      standardHtml = standardHtml.replace('[mobile1]', '| m');
      standardHtml = standardHtml.replace('[mobile2]', settings.mobile);
    } else {
      standardHtml = standardHtml.replace('[mobile1]', '');
      standardHtml = standardHtml.replace('[mobile2]', '');
    }
    if (settings.address && settings.address.length > 0) {
      standardHtml = standardHtml.replace('[address1]', '<div style="font-family:Arial,sans-serif;margin:0;font-size:10pt;font-weight:bold;color:#757575;padding-left:2px;display:inline-flex">a</div>');
      standardHtml = standardHtml.replace('[address2]', `<div style="font-family:Arial,sans-serif;margin:0;font-size:10pt;color:#757575;display:inline-flex;padding-right:2px">${settings.address} |</div>`);
    } else {
      standardHtml = standardHtml.replace('[address1]', '');
      standardHtml = standardHtml.replace('[address2]', '');
    }
    standardHtml = standardHtml.replace('[twitter]', settings.twitter && settings.twitter.length > 0 ? `<a href="${settings.twitter}" target="_blank"><img src="https://www.theinformationlab.co.uk/images/emailfooter/twitter.png" style="height:20px;width:20px;margin-right:10px;margin-top:3px"></a>` : '');
    standardHtml = standardHtml.replace('[linkedin]', settings.linkedin && settings.linkedin.length > 0 ? `<a href="${settings.linkedin}" target="_blank"><img src="https://www.theinformationlab.co.uk/images/emailfooter/linkedin.png" style="height:20px;width:20px;margin-right:10px"></a>` : '');
    standardHtml = standardHtml.replace('[tableau]', settings.tableau && settings.tableau.length > 0 ? `<a href="${settings.tableau}" target="_blank"><img src="https://www.theinformationlab.co.uk/images/emailfooter/tableau.png" style="height:20px;width:20px"></a>` : '');
    standardHtml = standardHtml.replaceAll('[email]', user.email || '');
    standardHtml = standardHtml.replaceAll('[domain]', domain || '');

    setNewSignature(standardHtml);
  }

  const handleRevert = () => {
    setSettings(oldSettings);
    handleUpdatePreview();
  }

  const handleGetCurrentSignature = () => {
    const token = localStorage.getItem('token');
    fetch('/api/google/signature', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            if (data && data.code && data.code === 401) {
              logout({ returnTo: window.location.href })
            }
            if (data && data.signature) {
                setCurrentSignature(data.signature);
                setNewSignature(data.signature);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
  };

  useEffect(() => {
    handleUpdatePreview();
    handleGetCurrentSignature();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateSettings = (name, value) => {
    const curSettings = {...settings};
    curSettings[name] = value;
    setSettings(curSettings);
  }

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
            <CurrentSignature signature={currentSignature} />
            <NewSignature 
            newSignature={newSignature} 
            getCurrentSignature={() => handleGetCurrentSignature()}
            logout={logout}/>
        </Grid>
        <Grid item md={6} xs={12}>
            <Settings 
              user={user} 
              settings={settings} 
              updateSettings={handleUpdateSettings} 
              updatePreview={handleUpdatePreview}
              revert={() => handleRevert()}
              />
        </Grid>
        </Grid>
      </div>
    )
  }

export default withAuthenticationRequired(Content, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <GeneralMessage type="loading" message={"Checking you're logged in..."} />,
});
