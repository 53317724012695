import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";

const classes = (theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const SalesOverviewViz = (props) => {
  const [viz, setViz] = useState(null);
  const [width, setWidth] = useState(getWidth());

  const vizRef = useRef(null);

  function loadViz(token, clientId) {
    console.log("[SalesOverviewViz.js] loadviz", token, clientId);
    let device = "desktop";
    const showMobile = width <= 800;
    const showTablet = width <= 1200 && !showMobile;
    if (showTablet) {
      device = "tablet"
    } else if (showMobile) {
      device = "phone"
    }
    let vizWidth = 1500;
    let vizHeight = 320;
    if (width > 1000) {
      vizWidth = (width / 3) * 2;
    }
    if (showTablet) {
      vizHeight = 620;
      vizWidth = 1000;
    } else if (showMobile) {
      vizWidth = (width / 3) * 2;
      vizHeight = 900;
    }

    console.log("[SalesOverviewViz.js] device", device);

    setViz(
      <tableau-viz 
        ref={vizRef}
        id="salesOverviewViz"       
        src={"https://tableauserver.theinformationlab.co.uk/t/til2/views/SalesPortalKPIs/PortalSalesKPI"}
        height={vizHeight}
        width={vizWidth}
        device={device}
        hide-tabs={false}
        token={token}
        toolbar='hidden'
        >
          {clientId && clientId.length > 0 ? <viz-filter field="Client ID" value={clientId}> </viz-filter> : null}
        </tableau-viz>
    );
  }

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
	if (props && props.clientId && props.clientId.length > 0 && props.tableauToken) {
		loadViz(props.tableauToken, props.clientId);
	}
    // return () => {
    //   setCurToken("");
    // };
    // eslint-disable-next-line
  }, [props.tableauToken, props.clientId, width]);

  return (
    <>
    <Helmet> 
        <script type="module" src="https://embedding.tableauusercontent.com/tableau.embedding.3.3.0.min.js" async></script>
      </Helmet>
    <div
      classes={{ root: classes.root }}
      style={{ marginLeft: width <= 600 ? -5 : 0 }}
    >
      {viz}
    </div>
    </>
  );
};

export default SalesOverviewViz;
