import React, { useState, useEffect } from "react";
// import { withRouter } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import DomainsTags from "./DomainsTags/DomainsTags";
import AccountManagers from "./AccountManagers/AccountManagers";
import SoftwareRenewals from "./SoftwareRenewals/SoftwareRenewals";
import CustomerSuccess from "./CustomerSuccess/CustomerSuccess";
import Sales from "./Sales/Sales";
import Breadcrumb from "../../../Breadcrumb/Breadcrumb";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import GeneralMessage from "../../../GeneralMessage/GeneralMessage";
import DataSchool from "./DataSchool/DataSchool";
import SalesOverview from "./SalesOverview/SalesOverview";

const classes = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 2),
    maxWidth: "98%",
    marginTop: 10,
    flexGrow: 1,
  },
  sectionTitle: {
    color: "rgba(0, 0, 0, 0.87);",
    flexGrow: 1,
  },
});

function Client(props) {
  const params = useParams();
  const [myClient, setMyClient] = useState({
    id: null,
    name: null,
    reportingName: null,
    sector: null,
    country: null,
    accountType: null,
    postcode: null,
    latitude: null,
    longitude: null,
    location: null,
    externalRef: null,
    tableauAM: null,
    alteryxAM: null,
    salesforceAM: null,
    cmmLevel: null,
    cmmRanking: null,
    clientDiaryUrl: null,
    sfdcCustomer: null,
    awsCustomer: null,
    endConversion: null,
    accountManagers: [],
    domains: [],
    tags: [],
    softwareRenewals: [],
    sales: [],
  });
  const [breadcrumbPath, setBreadcrumbPath] = useState([
    {
      label: "Clients",
      url: "/clients",
    },
  ]);
  const [tableauToken, setTableauToken] = useState();

  const clientQuery = gql`
    query Client($id: ID) {
      getClients(id: $id) {
        id
        name
        reportingName
        sector
        country
        accountType
        postcode
        postcodeVerified
        latitude
        longitude
        location
        cmmRanking
        cmmLevel
        externalRef
        capsuleId
        clientDiaryUrl
        sfdcCustomer
        awsCustomer
        endConversion
        tableauAM {
          id
          name
          email
          manager
          director
        }
        alteryxAM {
          id
          name
          email
          manager
          director
        }
        salesforceAM {
          id
          name
          email
          manager
          director
        }
        accountManagers {
          id
          amId
          name
          email
          country
          primaryOwner
          percentOwned
          fromDate
          toDate
          scope
          photo
        }
        domains {
          domain
          lastContacted
        }
        tags {
          tag
        }
        softwareRenewals {
          id
          originalOrderId
          softwareId
          softwareVendor
          softwareProduct
          stdPriceList {
            description
            itemType
            price
          }
          eduPriceList {
            description
            itemType
            price
          }
          qty
          renewalDate
          contractLength
          renewalYear
          comment
        }
        customerSuccess {
          checkIns {
            id
            type
            date
            outcome
          }
          convoUsers {
            id
            email
            firstName
            surname
            title
            lastLoginDate
            lastInviteReminderDate
            status
          }
        }
        sales {
          id
          date
          payee
          faInvoiceNumber
          faInvoiceUrl
          amountDollars
          amountEuro
          amountGbp
        }
        invoices {
          date
          reference
          url
          status
          dueDate
          netValue
          purchaseOrder
        }
        consultingDataSchool {
          firstName
          lastName
          hours
          email
          consultantStatus
          cohort
          placement
          placementStart
          placementEnd
          placementActive
          startDate
          lastDate
        }
      }
      getTableauJWT {
        jwt
        result
      }
    }
  `;

  const { loading, error, data } = useQuery(clientQuery, {
    variables: {
      id: params.id,
    },
  });

  useEffect(() => {
    if (data && data.getClients) {
      console.log("[Client.js] getClients", data);
      console.log("[Client.js] Loaded client", data.getClients[0]);
      setMyClient(data.getClients[0]);
      if (myClient.name) {
        const breadcrumb = [
          {
            label: "Clients",
            url: "/clients",
          },
        ];
        breadcrumb.push({ label: myClient.name });
        setBreadcrumbPath(breadcrumb);
      }
    } /* eslint-disable */
    if (data && data.getTableauJWT && data.getTableauJWT.jwt) {
      setTableauToken(data.getTableauJWT.jwt);
    }
  }, [data, myClient.name]); /* eslint-enable */

  if (!props.salesAccess) {
    console.log('[Client.js] No access to single Client page', props.salesAccess);
    return (
      <GeneralMessage type="error" message="No Sales Access"/>
    )
  }

  return (
    <div className={classes.root}>
      <Breadcrumb path={breadcrumbPath} error={error} />
      <LinearProgress
        style={{
          display: loading ? "inherit" : "none",
          marginLeft: -12,
          marginRight: -12,
        }}
      />
      <Grid container>
        <Grid item xs={12} sm={3} >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <CompanyInfo client={myClient} style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DomainsTags client={myClient} style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9} className="pl-0 sm:pl-2 mt-2 sm:mt-0">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <AccountManagers client={myClient} style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SalesOverview tableauToken={tableauToken} client={myClient} style={{ maxWidth: "100%" }} />
            </Grid>
            {myClient.consultingDataSchool && myClient.consultingDataSchool.length > 0 ?
              <Grid item xs={12} sm={12}>
                <DataSchool tableauToken={tableauToken} externalRef={myClient.externalRef} dataschoolConsultants={myClient.consultingDataSchool}/>
              </Grid>
            : null }
            <Grid item xs={12} sm={12}>
              <SoftwareRenewals
                client={myClient}
                style={{ maxWidth: "100%" }}
                auth0Profile={props.auth0Profile}
                refreshAuth0={props.refreshAuth0}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <CustomerSuccess client={myClient} style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Sales client={myClient} style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAuthenticationRequired(Client, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <GeneralMessage type="loading" message={"Checking you're logged in..."} />,
});
