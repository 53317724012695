import React, {useState, useEffect} from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import GeneralMessage from "../../GeneralMessage/GeneralMessage";
import Grid2 from '@mui/material/Unstable_Grid2';
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const classes = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

const sortObject = obj => Object.keys(obj).sort().reduce((res, key) => (res[key] = obj[key], res), {})

function Content({auth0Profile}) {
	const [ locations, setLocations ] = useState([]);
  	const { user } = useAuth0();

	const locationQuery = gql`
      query location {
        getMacAddressAllocation (today: true) {
          mac
          name
          floor
          site
          avatar
          updated
      }
    }
  `;

  const { loading, data, refetch } = useQuery(locationQuery, {
    fetchPolicy: "cache-and-network",
    pollInterval: (1000 * 60 * 5), // Refresh every 5 mins
  });
  
  useEffect(() => {
    if (data && data.getMacAddressAllocation) {
      let layout = {};
      data.getMacAddressAllocation.forEach((person) => {
        if (layout[person.floor] !== undefined) {
          layout[person.floor] = [...layout[person.floor], person];
        } else {
          layout[person.floor] = [person];
        }
      })
      
      setLocations(sortObject(layout));
    }
  }, [data])

    return (
      <div className={classes.root}>
         {!loading && data && data.getMacAddressAllocation &&  data.getMacAddressAllocation.length > 0 ? (
          <>
          {Object.keys(locations).reverse().map(key => {
              return (
                <div key={key}>
                  <h2 className="text-gray-800 text-xl">Floor {key}</h2>
                  <Grid2 container spacing={3} className="p-4">
                    <Grid2 item className="grid grid-cols-1">
                      
                    </Grid2>
                  </Grid2>
                </div>
              )
          })}
          </>
      ) : !loading && data && data.getMacAddressAllocation &&  data.getMacAddressAllocation.length === 0 ? (
        <>No Users Online</>
    ) : <>Loading</>}
      </div>
    )
  }

export default withAuthenticationRequired(Content, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <GeneralMessage type="loading" message={"Checking you're logged in..."} />,
});
